import React, { useState, useContext, useEffect, useRef } from 'react';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

// Import API_BASE_URL correctly
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Checkout({ user }) {
  const { cart, clearCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const errorRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  // Use effect to populate form fields with user data when available
  useEffect(() => {
    
    if (user) {
      setFirstName(user.address.firstName || '');
      setLastName(user.address.lastName || '');
      setPhoneNumber(user.address.phoneNumber || '');
      setAddress1(user.address.addressLine1 || '');
      setAddress2(user.address.addressLine2 || '');
      setCity(user.address.city || '');
      setState(user.address.state || '');
      setZipCode(user.address.postalCode || '');
      setGuestEmail(user.email)
    }
  }, [user]);

  const total = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear any previous error messages

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(guestEmail)) {
      setErrorMessage('Please enter a valid email address.');
      return; // Stop submission if validation fails
    }

    //Validate reCAPTCHA
    if (!captchaValue) {
      setErrorMessage('Please complete the reCAPTCHA.');
      return; // Stop submission if reCAPTCHA is not completed
    }

    const orderData = {
      user: user ? user.username : 'Guest',
      firstName,
      lastName,
      email:  guestEmail,
      phoneNumber,
      shippingAddress: {
        address1,
        address2,
        city,
        state,
        postalCode: zipCode,
        UserID: user ? user.address.userID : null
      },
      cart,
      total
    };

    try {
      const response = await fetch(API_BASE_URL + '/api/goldentouch/add-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error('There was a problem processing your order. Please try again.');
      }

      const result = await response.json();
      console.log('Order submitted successfully:', result);

      clearCart();
      setOrderPlaced(true);
    } catch (error) {
      console.error('Error submitting order:', error);
      setErrorMessage(error.message);
      // Scroll to error message
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const handleOkClick = () => {
    navigate('/');
  };

  if (orderPlaced) {
    return (
      <div className="order-placed-container">
        <h2>Your order has been placed!</h2>
        <button 
          onClick={handleOkClick}
          className="ok-button"
        >
          OK
        </button>
      </div>
    );
  }

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>
      <div className="checkout-content">
        <form onSubmit={handleSubmit} className="checkout-form">
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              readOnly={!!user}
            />
          </div>
         
            <div className="form-group">
              <label htmlFor="guestEmail">Email:</label>
              <input
                type="email"
                id="guestEmail"
                value={guestEmail}
                onChange={(e) => setGuestEmail(e.target.value)}
                required
              />
            </div>
          
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel" // Changed to 'tel' for better mobile keyboard
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))} // Allow only digits
              required
              readOnly={!!user}
              pattern="\d*" // Allow only numeric input
            />
          </div>
          <div className="form-group">
            <label htmlFor="address1">Address Line 1:</label>
            <input
              type="text"
              id="address1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              required
              readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address2">Address Line 2:</label>
            <input
              type="text"
              id="address2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
              readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="zipCode">ZIP Code:</label>
            <input
              type="text"
              id="zipCode"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              readOnly={!!user}
            />
          </div>
           <ReCAPTCHA
            sitekey="6LcTuGAqAAAAAI0LuZ7id16hn6GaFOJxORJIhvl6" // Replace with your site key
            onChange={(value) => setCaptchaValue(value)}
          /> 
          <button type="submit" className="place-order-btn">Place Order</button>
          {errorMessage && (
            <div ref={errorRef} className="error-message">
              {errorMessage}
            </div>
          )}
        </form>
        <div className="order-summary">
          <h3>Order Summary</h3>
          {cart.map((item) => (
            <div key={item.id} className="order-item">
              <span className="item-name">{item.title}</span>
              <span className="item-quantity">x {item.quantity}</span>
              <span className="item-price">${(item.price * item.quantity).toFixed(2)}</span>
            </div>
          ))}
          <div className="order-total">
            <strong>Total:</strong> ${total.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
