import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function AddColor() {
  const [colorName, setColorName] = useState('');
  const [colorId, setColorId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showOkButton, setShowOkButton] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.color) {
      const { ColorID, ColorName } = location.state.color;
      setColorId(ColorID);
      setColorName(ColorName || '');
      setIsEditing(true);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${API_BASE_URL}/api/AdminGoldenTouch/add-color`;
      const params = new URLSearchParams({
        colorID: colorId || 0,
        colorName: colorName
      });
      
      const response = await axios.get(`${url}?${params}`,{      withCredentials: true});
      console.log(JSON.stringify(response.data));
      
      setSuccessMessage(isEditing ? 'Color successfully edited!' : 'Color successfully added!');
      setShowOkButton(true);
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    navigate('/admin/colors');
  };

  const handleOkClick = () => {
    navigate('/admin/colors');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this color?')) {
      try {
        const url = `${API_BASE_URL}/api/AdminGoldenTouch/delete-color`;
        const params = new URLSearchParams({ colorID: colorId });
        
        const response = await axios.delete(`${url}?${params}`,{      withCredentials: true});
        console.log(JSON.stringify(response.data));
        
        setSuccessMessage('Color successfully deleted!');
        setShowOkButton(true);
      } catch (error) {
        console.error('Error:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  return (
    <div className="add-category">
      <button onClick={handleBack} className="back-button">← Back to Colors</button>
      <br/> <br/> <br/>
      <h2>{isEditing ? 'Edit Color' : 'Add New Color'}</h2>
      {successMessage ? (
        <div className="success-message">
          {successMessage}
          {showOkButton && (
            <button onClick={handleOkClick} className="ok-button">OK</button>
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="colorName">Color Name:</label>
            <input
              type="text"
              id="colorName"
              value={colorName}
              onChange={(e) => setColorName(e.target.value)}
              required
            />
          </div>
          <div className="button-group">
            {isEditing && (
              <button 
                type="button" 
                onClick={handleDelete} 
                className="delete-button"
                style={{ backgroundColor: 'red', color: 'white' }}
              >
                Delete Color
              </button>
            )}
            <button type="submit" className="update-button">
              {isEditing ? 'Update Color' : 'Add Color'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default AddColor;
