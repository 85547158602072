import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import CategoryMenu from '../components/CategoryMenu';
import '../css/CategoryProductsPage.css';
import mainImage from '../assets/gd.jpg'; // Import the main image
import { useNavigate } from 'react-router-dom';


const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function CategoryProductsPage() {
  const { categoryId } = useParams();
  const [allProducts, setAllProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Default page size for product grid
  const navigate = useNavigate();
  useEffect(() => {
    if (categoryId === '1') { // Check if categoryId is '1'
      navigate('/')

    } else {
      fetchProductsByCategory(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    updateDisplayedProducts();
  }, [allProducts, currentPage, pageSize]);

  const fetchProductsByCategory = async (id) => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/GoldenTouch/get-product-by-category?categoryID=${id}`,
    };

    try {
      const response = await axios(config);
      setAllProducts(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching products. Please try again later.');
      setLoading(false);
    }
  };

  const updateDisplayedProducts = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedProducts(allProducts.slice(startIndex, endIndex));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(allProducts.length / pageSize);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const handleImageError = (e, productTitle) => {
    console.error(`Failed to load image for product: ${productTitle}`);
    e.target.src = "https://via.placeholder.com/150";
  };

  return (
    <div className="category-products-page">
      <img src={mainImage} alt="Golden Denim" 
        style={{
          width: '50%', // Adjust this value to make the image smaller or larger
          height: 'auto',
          display: 'block',
          margin: '0 auto',
        }}/>
      <div className="content-wrapper">
        <div className="sidebar">
          <CategoryMenu />
        </div>
        <div className="product-section">
          <div className="table-controls">
            <div className="page-size-selector">
              <label htmlFor="pageSize">Products per page:</label>
              <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="48">48</option>
              </select>
            </div>
          </div>
          <div className="product-grid">
            {displayedProducts.length === 0 ? (
              <p>No products available in this category</p>
            ) : (
              displayedProducts.map((product) => {
                const imagePath = product.ProductToVariations?.[0]?.ProductToVariationToImages?.[0]?.ImagePath;
                const imageUrl = imagePath ? `${API_BASE_URL}/${imagePath}` : "https://via.placeholder.com/150";
                
                console.log('Product Image URL:', imageUrl); // Log the image URL

                return (
                  <Link to={`/product/${product.ProductID}`} key={product.ProductID} className="product-card">
                    <div className="image-container">
                      <img 
                        src={imageUrl}
                        alt={product.Title}
                        onError={(e) => handleImageError(e, product.Title)}
                      />
                    </div>
                    <h3 dangerouslySetInnerHTML={{ __html: product.Title }}></h3>
                    <p className="price">${product.ProductToVariations[0].Price}</p>
                  </Link>
                );
              })
            )}
          </div>
          <div className="pagination-container">
            <div className="pagination">
              <button 
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}
                className="pagination-button"
              >
                &lt; Previous
              </button>
              <span className="page-info">Page {currentPage} of {totalPages}</span>
              <button 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryProductsPage;
