import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from '../contexts/CartContext';
import '../css/ProductDetailsPage.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function ProductDetailsPage() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showColor, setShowColor] = useState(true);
  const [showCasePack, setCasePack] = useState(true);

  const [selectedCasepackID, setSelectedCasepackID] = useState('');
  const [selectedColorID, setSelectedColorID] = useState('');
  const [selectedSizeID, setSelectedSizeID] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [price, setPrice] = useState(0);


  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    fetchProductDetails(productId);

  }, [productId]);

  useEffect(() => {
    checkAvailability();
  }, [selectedCasepackID, selectedColorID, selectedSizeID, product]);

  const fetchProductDetails = async (id) => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/GoldenTouch/get-product?productID=${id}`,
    };

    try {
      const response = await axios(config);
      setProduct(response.data[0]); // Assuming the API returns an array with a single product
      console.log(response.data[0])
      setPrice(response.data[0].ProductToVariations[0].Price.toFixed(2));


      const CasepackIDs = [...new Set(response.data[0].ProductToVariations.map(v => v.CasePackID))];
      const ColorIDs = [...new Set(response.data[0].ProductToVariations.map(v => v.ColorID))];
      const SizeIDs = [...new Set(response.data[0].ProductToVariations.map(v => v.SizeID))];
      console.log(ColorIDs[0])
      if(ColorIDs.length===1)
      {
        if(ColorIDs[0]===128)
        {
          setShowColor(false);
        }
        
      }
      else
      {
        setShowColor(true);
      }

      if(CasepackIDs.length===1)
        {
          if(CasepackIDs[0]===55)
          {
            setCasePack(false);
          }
          
        }
        else
        {
          setCasePack(true);
        }

      
    
      setLoading(false);
    } catch (err) {
      setError('Error fetching product details. Please try again later.');
      setLoading(false);
    }
  };

  const checkAvailability = () => {
    var ColorI = selectedColorID;
    
    if(!showColor)
    {
      ColorI='128'
    }
    console.log("I" + ColorI)

    var CPI = selectedCasepackID;

    
    if(!showCasePack)
    {
      CPI='128'
    }
    console.log("C" + CPI)


    if (!product || !CPI || !ColorI || !selectedSizeID) {
      setIsAvailable(false);
      //setPrice(product.ProductToVariations[0].Price.toFixed(2));
      return;
    }
    

    const selectedVariation = product.ProductToVariations.find(
      variation =>
        variation.CasePackID === parseInt(CPI) &&
        variation.ColorID === parseInt(ColorI) &&
        variation.SizeID === parseInt(selectedSizeID)
    );
console.log(selectedVariation)
    if (selectedVariation) {
      setIsAvailable(true);
      setPrice(selectedVariation.Price);
      if(selectedVariation.AvailableStock <=0)
      {
        console.log('no stock')
        setIsAvailable(false);
      }
    } else {
      console.log('not theres')
      setIsAvailable(false);
      setPrice(product.ProductToVariations[0].Price.toFixed(2));
    }
  };

  const handleAddToCart = () => {
  
    if (!isAvailable || !product) return;

    var ColorI = selectedColorID;

    
    if(!showColor)
    {
      ColorI='128'
    }
    console.log("I" + ColorI)


    var CPI = selectedCasepackID;

    
    if(!showCasePack)
    {
      CPI='128'
    }
    console.log("C" + CPI)


    const selectedVariation = product.ProductToVariations.find(
      variation =>
        variation.CasePackID === parseInt(CPI) &&
        variation.ColorID === parseInt(ColorI) &&
        variation.SizeID === parseInt(selectedSizeID)
    );
   
    
    if (!selectedVariation) return;
    alert('hi')
    const cartItem = {
      id: selectedVariation.ProductToVariationID,
      productId: product.ProductID,
      title: product.Title,
      price: selectedVariation.Price,
      casepack: selectedVariation.CasePack.CasePackName,
      color: selectedVariation.Color.ColorName,
      size: selectedVariation.Size.SizeName,
      quantity: 1,
      ImagePath: selectedVariation.ProductToVariationToImages[0]?.ImagePath || '',
    };

    addToCart(cartItem);
    alert('Product added to cart!');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!product) return <div>Product not found</div>;

  const CasepackIDs = [...new Set(product.ProductToVariations.map(v => v.CasePackID))];
  const ColorIDs = [...new Set(product.ProductToVariations.map(v => v.ColorID))];
  const SizeIDs = [...new Set(product.ProductToVariations.map(v => v.SizeID))];


  return (
    <div className="product-details-page">
      <div className="product-images-container">
        <div className="main-image-container">
          {product.ProductToVariations.flatMap(v => v.ProductToVariationToImages)[selectedImageIndex] && (
            <img
              src={API_BASE_URL + "/" + product.ProductToVariations.flatMap(v => v.ProductToVariationToImages)[selectedImageIndex].ImagePath}
              alt={`Product image ${selectedImageIndex + 1}`}
              className="main-image"
            />
          )}
        </div>
        <div className="thumbnail-images-container">
          {product.ProductToVariations.flatMap(v => v.ProductToVariationToImages).map((image, index) => (
            <img
              key={index}
              src={API_BASE_URL + "/" + image.ImagePath}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => setSelectedImageIndex(index)}
              className={`thumbnail-image ${index === selectedImageIndex ? 'selected' : ''}`}
            />
          ))}
        </div>
      </div>
      <div className="product-info">
        <h1 dangerouslySetInnerHTML={{ __html: product.Title }}></h1>
        <p className="price">${price}</p>
        <p dangerouslySetInnerHTML={{ __html: product.Description }}></p>

      
        <div className="product-options">
        {showCasePack && (
          <select value={selectedCasepackID} onChange={(e) => setSelectedCasepackID(e.target.value)}>
            <option value="">Select Casepack</option>
            {CasepackIDs.map(CasepackID => (
              <option key={CasepackID} value={CasepackID}>
                {product.ProductToVariations.find(v => v.CasePackID === CasepackID).CasePack.CasePackName}
              </option>
            ))}
          </select>
        )}

          {showColor && (
            <select value={selectedColorID} onChange={(e) => setSelectedColorID(e.target.value)}>
              <option value="">Select Color</option>
              {ColorIDs.map(ColorID => (
                <option key={ColorID} value={ColorID}>
                  {product.ProductToVariations.find(v => v.ColorID === ColorID).Color.ColorName}
                </option>
              ))}
            </select>
          )}
          
          <select value={selectedSizeID} onChange={(e) => setSelectedSizeID(e.target.value)}>
            <option value="">Select Size</option>
            {SizeIDs.map(SizeID => (
              <option key={SizeID} value={SizeID}>
                {product.ProductToVariations.find(v => v.SizeID === SizeID).Size.SizeName}
              </option>
            ))}
          </select>
        </div>
        
        <button 
          className={`add-to-cart-button ${!isAvailable ? 'unavailable' : ''}`}
          disabled={!isAvailable}
          onClick={handleAddToCart}
        >
          {!selectedCasepackID && !selectedColorID && !selectedSizeID
            ? 'Choose Variation'
            : isAvailable
            ? 'Add to Cart'
            : 'Unavailable'}
        </button>
      </div>
    </div>
  );
}

export default ProductDetailsPage;
