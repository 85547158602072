// src/pages/EditProductPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select'; // Add this import
import '../index.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function EditProductPage() {
  const { id } = useParams();
  const isNewProduct = id === '0';
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState('');
  const [isFeatured, setIsFeatured] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [variations, setVariations] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [casePackOptions, setCasePackOptions] = useState([]);
  const [gender, setGender] = useState('');
  const [genderOptions, setGenderOptions] = useState([]);
  const [removedVariationIds, setRemovedVariationIds] = useState([]);
  const [brand, setBrand] = useState('');
  const [brandOptions, setBrandOptions] = useState([]);
  const [removedImageIds, setRemovedImageIds] = useState([]); // Add this line

  const descriptionRef = useRef(null);
  const navigate = useNavigate();

  // Convert options to the format required by react-select
  const categoryOptions = categories.map(cat => ({ value: cat.CategoryID, label: cat.CategoryName }));
  const genderOptions2 = genderOptions.map(gen => ({ value: gen.GenderID, label: gen.GenderName }));
  const brandOptions2 = brandOptions.map(brand => ({ value: brand.BrandID, label: brand.BrandName }));
  const sizeSelectOptions = sizeOptions.map(size => ({ value: size.SizeID, label: size.SizeName }));
  const colorSelectOptions = colorOptions.map(color => ({ value: color.ColorID, label: color.ColorName }));
  const casePackSelectOptions = casePackOptions.map(casePack => ({ value: casePack.CasePackID, label: casePack.CasePackName }));

  useEffect(() => {
    fetchCategories();
    fetchOptions();
    fetchGenders();
    fetchBrands(); // Add this line
    if (!isNewProduct) {
      fetchProductData();
    } else {
      setLoading(false);
      setVariations([]);
      setName('');
      setDescription('');
      setCategory('');
      setIsFeatured(false);
      setGender('');
      setBrand('');
    }
  }, [id, isNewProduct]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/goldentouch/get-categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories. Please try again.');
    }
  };

  const fetchProductData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/goldentouch/get-product?productID=${id}`);
      const product = response.data[0];
      setVariations(product.ProductToVariations);
      setName(product.Title);
      setDescription(product.Description);
      setCategory(product.Category?.CategoryID);
      setIsFeatured(product.IsFeatured);
      setGender(product.GenderID);
      setBrand(product.BrandID);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product data:', error);
      setError('Failed to fetch product data. Please try again.');
      setLoading(false);
    }
  };

  const fetchOptions = async () => {
    try {
      const [sizeResponse, colorResponse, casePackResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/goldentouch/get-sizes`),
        axios.get(`${API_BASE_URL}/api/goldentouch/get-colors`),
        axios.get(`${API_BASE_URL}/api/goldentouch/get-case-packs`)
      ]);
      setSizeOptions(sizeResponse.data);
      setColorOptions(colorResponse.data);
      setCasePackOptions(casePackResponse.data);
    } catch (error) {
      console.error('Error fetching options:', error);
      setError('Failed to fetch options. Please try again.');
    }
  };

  const fetchGenders = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/goldentouch/get-genders`);
      setGenderOptions(response.data);
    } catch (error) {
      console.error('Error fetching genders:', error);
      setError('Failed to fetch genders. Please try again.');
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/goldentouch/get-brands`);
      setBrandOptions(response.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
      setError('Failed to fetch brands. Please try again.');
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleAddVariation = () => {
    setVariations([...variations, {
      SkuNumber: '',
      SizeID: 0,
      ColorID: 0,
      Stock: 0,
      Price: 0,
      OnHandStock:0,
      AvailableStock:0,
      CasePackID: 0,
      Images: [],
      IsNew: true,
      ProductID: 0,  // Assuming 'id' is the current product's ID
      Title: '',
      Handle: '',
      Location: '',
      Description: '',
      CategoryID: category,  // Using the current category
      GenderID: gender,  // Using the current gender
      BrandID: '',  // You might want to add a state for BrandID if not already present
      DateCreated: new Date().toISOString(),
      IsFeatured: false,
      Price:0,
      ProductToVariationID:0,
      ProductToVariationToImages :[],
      IsActive:false
    }]);
  };

  const handleVariationChange = (index, field, value) => {
    const updatedVariations = [...variations];
    updatedVariations[index][field] = value;
    setVariations(updatedVariations);
  };

  const handleVariationImageChange = (index, e) => {
    const files = Array.from(e.target.files);
    const newVariations = [...variations];
    console.log(files[0])
    newVariations[index].ProductToVariationToImages = newVariations[index].ProductToVariationToImages || [];
    newVariations[index].ProductToVariationToImages = [
      ...newVariations[index].ProductToVariationToImages,
      ...files.map(file => ({
        file,
        preview: URL.createObjectURL(file)
      }))
    ];
    setVariations(newVariations);
  };

  const removeVariationImage = (variationIndex, imageIndex) => {
    const newVariations = [...variations];
    const removedImage = newVariations[variationIndex].ProductToVariationToImages.splice(imageIndex, 1)[0];
    setVariations(newVariations);

    // If the removed image has an ID, add it to the removedImageIds
    if (removedImage.ProductToVariationToImageID) {
      setRemovedImageIds([...removedImageIds, removedImage.ProductToVariationToImageID]);
    }
  };

  const handleRemoveVariation = (index) => {
    const newVariations = [...variations];
    const removedVariation = newVariations.splice(index, 1)[0];
    setVariations(newVariations);

    // If the removed variation has an ID, add it to the removedVariationIds
    if (removedVariation.ProductToVariationID) {
      setRemovedVariationIds([...removedVariationIds, removedVariation.ProductToVariationID]);
    }
  };

  const handleDeleteProduct = async () => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`${API_BASE_URL}/api/AdminGoldenTouch/delete-product?productID=${id}`,{      withCredentials: true});
        alert('Product deleted successfully!');
        navigate('/admin/products');
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('Error deleting product. Please try again.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('Title', name);
    formData.append('ProductID', isNewProduct ? '0' : id);
    formData.append('Description', descriptionRef.current.innerHTML);
    formData.append('CategoryID', category);
    if (image) {
      formData.append('Image', image);
    }
    formData.append('IsFeatured', isFeatured);
    formData.append('Gender', gender);
    formData.append('BrandID', brand);
    console.log(variations)
    variations.forEach((variation, index) => {
      formData.append(`SKU[${index}]`, variation.SkuNumber);
      formData.append(`IsActive[${index}]`, variation.IsActive);
      formData.append(`VariationID[${index}]`, variation.ProductToVariationID || '0');
      formData.append(`SizeID[${index}]`, variation.SizeID);
      formData.append(`ColorID[${index}]`, variation.ColorID);
      formData.append(`CasePackID[${index}]`, variation.CasePackID);
      formData.append(`OnHandStock[${index}]`, variation.OnHandStock);
      formData.append(`Price[${index}]`, variation.Price);
      variation.ProductToVariationToImages.forEach((image, imageIndex) => {
        formData.append(`Images[${index}][${imageIndex}]`, image.file);
      });
    });

    // Add the removed variation IDs to the formData
    removedVariationIds.forEach((id, index) => {
      formData.append(`RemovedVariationIDs[${index}]`, id);
    });

    // Add the removed image IDs to the formData
    removedImageIds.forEach((id, index) => {
      formData.append(`RemovedImageIDs[${index}]`, id);
    });

    try {
      await axios.post(`${API_BASE_URL}/api/AdminGoldenTouch/products`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      alert(isNewProduct ? 'Product created successfully!' : 'Product updated successfully!');
      navigate('/admin/products'); // Redirect to products page
    } catch (error) {
      console.error('Error saving product:', error);
      alert('Error saving product. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="admin-edit-product">
      <h2>{isNewProduct ? 'Add New Product' : 'Edit Product'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={isFeatured}
              onChange={(e) => setIsFeatured(e.target.checked)}
            />
            Featured Product
          </label>
       
        </div>

        <div className="form-group">
          <label htmlFor="name">Product Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <div
            ref={descriptionRef}
            contentEditable
            dangerouslySetInnerHTML={{ __html: description }}
            className="editable-description"
            style={{
              border: '1px solid #ccc',
              minHeight: '100px',
              padding: '8px',
              borderRadius: '4px'
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <Select
            id="category"
            value={categoryOptions.find(option => option.value === category)}
            onChange={(selectedOption) => setCategory(selectedOption.value)}
            options={categoryOptions}
            isSearchable={true}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <Select
            id="gender"
            value={genderOptions2.find(option => option.value === gender)}
            onChange={(selectedOption) => setGender(selectedOption.value)}
            options={genderOptions2}
            isSearchable={true}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="brand">Brand</label>
          <Select
            id="brand"
            value={brandOptions2.find(option => option.value === brand)}
            onChange={(selectedOption) => setBrand(selectedOption.value)}
            options={brandOptions2}
            isSearchable={true}
            required
          />
        </div>

        <h3>Product Variations</h3>
        <table className="variations-table">
          <thead>
            <tr>
              <th className="sku-column">SKU</th>
              <th className="size-column">Size</th>
              <th className="color-column">Color</th>
              <th className="case-pack-column">Case Pack</th>
              <th className="stock-column">On Hand Stock</th>
              <th className="stock-column2">Available Stock</th>
              <th className="price-column">Price</th>
              <th className="images-column">Images</th>
              <th className="is-active-column">Active</th>
              <th className="action-column">Action</th>
            </tr>
          </thead>
          <tbody>
            {variations.map((variation, index) => (
              <tr key={index}>
                <td className="sku-column">
                  <input
                    type="text"
                    value={variation.SkuNumber}
                    onChange={(e) => handleVariationChange(index, 'SkuNumber', e.target.value)}
                    placeholder="Enter SKU"
                  />
                </td>
                <td className="size-column">
                  <Select
                    value={sizeSelectOptions.find(option => option.value === variation.SizeID)}
                    onChange={(selectedOption) => handleVariationChange(index, 'SizeID', selectedOption.value)}
                    options={sizeSelectOptions}
                    isSearchable={true}
                  />
                </td>
                <td className="color-column">
                  <Select
                    value={colorSelectOptions.find(option => option.value === variation.ColorID)}
                    onChange={(selectedOption) => handleVariationChange(index, 'ColorID', selectedOption.value)}
                    options={colorSelectOptions}
                    isSearchable={true}
                  />
                </td>
                <td className="case-pack-column">
                  <Select
                    value={casePackSelectOptions.find(option => option.value === variation.CasePackID)}
                    onChange={(selectedOption) => handleVariationChange(index, 'CasePackID', selectedOption.value)}
                    options={casePackSelectOptions}
                    isSearchable={true}
                  />
                </td>
                <td className="stock-column">
                  <input
                    type="number"
                    value={variation.OnHandStock}
                    onChange={(e) => handleVariationChange(index, 'OnHandStock', parseInt(e.target.value))}
                  />
                </td>
                <td className="stock-column2">
                  <input
                  disabled
                    type="number"
                    value={variation.AvailableStock}
                    onChange={(e) => handleVariationChange(index, 'AvailableStock', parseInt(e.target.value))}
                  />
                </td>
                <td className="price-column">
                  <input
                    type="number"
                    value={variation.Price}
                    onChange={(e) => handleVariationChange(index, 'Price', parseFloat(e.target.value))}
                  />
                </td>
                <td className="images-column">
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleVariationImageChange(index, e)}
                  />
                  <div className="image-previews">
                    {(variation.ProductToVariationToImages || []).map((image, imageIndex) => (
                      <div key={imageIndex} className="image-preview-container">
                        <img 
                          src={`${API_BASE_URL}${image.ImagePath}`}
                          alt={`Variation ${index + 1} Image ${imageIndex + 1}`} 
                          className="image-preview"
                          style={
                            {
                              height:"100px"
                            }
                          }
                        />
                        <button 
                          type="button" 
                          onClick={() => removeVariationImage(index, imageIndex)}
                          className="remove-image-btn"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="is-active-column">
                  <input
                    type="checkbox"
                    checked={variation.IsActive}
                    onChange={(e) => handleVariationChange(index, 'IsActive', e.target.checked)}
                  />
                </td>
                <td className="action-column">
                  <button 
                    type="button" 
                    onClick={() => handleRemoveVariation(index)}
                    className="remove-variation-btn"
                  >
                    X
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" onClick={handleAddVariation} className="add-variation-btn">
          + Add Variation
        </button>

        <div className="form-group">
          <button type="submit" className="submit-btn">
            {isNewProduct ? 'Add Product' : 'Update Product'}
          </button>
        </div>
      </form>

      {!isNewProduct && (
        <div className="delete-product-section">
          <button 
            type="button" 
            onClick={handleDeleteProduct}
            className="delete-product-btn"
          >
            Delete Product
          </button>
        </div>
      )}
    </div>
  );
}

export default EditProductPage;