import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Import API_BASE_URL correctly
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function AddCategory() {
  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showOkButton, setShowOkButton] = useState(false);
  const [showOnHomepage, setShowOnHomepage] = useState(false); // New state for checkbox
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.category) {
      const { CategoryID, CategoryName,ShowOnHomePage } = location.state.category;
      console.log(location.state.category)
      setCategoryId(CategoryID);
      setCategoryName(CategoryName || '');
      setShowOnHomepage(ShowOnHomePage)
      setIsEditing(true);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${API_BASE_URL}/api/AdminGoldenTouch/add-category`;
      const params = new URLSearchParams({
        categoryID: categoryId || 0,
        categoryName: categoryName,
        show: showOnHomepage, // Include checkbox value in params
      });
      
      const response = await axios.get(`${url}?${params}`,{      withCredentials: true});
      console.log(JSON.stringify(response.data));
      
      // Set success message and show OK button
      setSuccessMessage(isEditing ? 'Category successfully edited!' : 'Category successfully added!');
      setShowOkButton(true);
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    navigate('/admin/categories');
  };

  const handleOkClick = () => {
    navigate('/admin/categories');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        const url = `${API_BASE_URL}/api/AdminGoldenTouch/delete-category`;
        const params = new URLSearchParams({ categoryID: categoryId });
        
        const response = await axios.delete(`${url}?${params}`,{      withCredentials: true});
        console.log(JSON.stringify(response.data));
        
        setSuccessMessage('Category successfully deleted!');
        setShowOkButton(true);
      } catch (error) {
        console.error('Error:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  return (
    <div className="add-category">
      <button onClick={handleBack} className="back-button">← Back to Categories</button>
      <br/> <br/> <br/>
      <h2>{isEditing ? 'Edit Category' : 'Add New Category'}</h2>
      {successMessage ? (
        <div className="success-message">
          {successMessage}
          {showOkButton && (
            <button onClick={handleOkClick} className="ok-button">OK</button>
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="categoryName">Category Name:</label>
            <input
              type="text"
              id="categoryName"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={showOnHomepage}
                onChange={(e) => setShowOnHomepage(e.target.checked)} // Update state on change
              />
              Show On Homepage
            </label>
          </div>
          <div className="button-group">
            {isEditing && (
              <button 
                type="button" 
                onClick={handleDelete} 
                className="delete-button"
                style={{ backgroundColor: 'red', color: 'white' }}
              >
                Delete Category
              </button>
            )}
            <button type="submit" className="update-button">
              {isEditing ? 'Update Category' : 'Add Category'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default AddCategory;
