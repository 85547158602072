import React from 'react';
import { Navigate } from 'react-router-dom';

function AdminRoute({ user,children }) {

  console.log('AdminRoute - user:', user);
  console.log('AdminRoute - user type:', typeof user);


  if (!user || (user && !user.isAdmin)) {
    console.log('AdminRoute - User is null, redirecting to login');
    return <Navigate to="/" replace />;
  }

  console.log('AdminRoute - Rendering children (admin access granted)');
  return children;
}

export default AdminRoute;
